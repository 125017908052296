<template>
  <div class="page">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #searchSlot>
        <v-input
          label="通知标题:"
          v-model="searchParam.topicTitle"
          placeholder="请输入通知标题"
        />
        <v-date-picker
          label="创建时间"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="创建开始时间"
          endPlaceholder="创建结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="删除"
          disable
          type="text"
          @click="deleteTopic(scope.row)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getTopicListURL, deleteTopicUrl } from "./api.js";
import { createImgVNode } from "@/utils/utils.js";
export default {
  name: "residentTopicList",
  data() {
    return {
      searchParam: {
        topicType: 40, // 管家通知
      },
      tableUrl: getTopicListURL,
      headers: [
        {
          prop: "topicPicture",
          label: "通知封面图",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "topicTitle",
          label: "通知标题",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "topicContent",
          label: "通知内容",
        },
        {
          prop: "praiseCount",
          label: "点赞数目",
        },
        {
          prop: "commentCount",
          label: "评论数目",
        },
        {
          prop: "readCount",
          label: "浏览数目",
        },
      ],
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.createTimeS && !this.searchParam.createTimeE
          ? []
          : [this.searchParam.createTimeS, this.searchParam.createTimeE];
      },
      set(val) {
        [this.searchParam.createTimeS, this.searchParam.createTimeE] = val || [
          "",
          "",
        ];
      },
    },
  },
  methods: {
    deleteTopic(item) {
      //删除管家通知
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.id,
        };
        this.$axios.post(`${deleteTopicUrl}`, null, { params }).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          } else {
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
